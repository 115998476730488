<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ClearLayout",
});
</script>

<template>
  <router-view />
</template>

<style scoped lang="scss"></style>
