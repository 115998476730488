<template>
  <header class="header">
    <div class="container">
      <router-link :to="{ name: 'HomeView' }">
        <img src="./assets/logo.svg" alt="logotype" />
      </router-link>
    </div>
  </header>

  <router-view />

  <footer class="footer">
    <div class="container">
      <img src="./assets/logo.svg" alt="" />

      <nav>
        <router-link :to="{ name: 'HomeView', hash: '#hot' }">
          Горящие туры
        </router-link>
        <router-link :to="{ name: 'HomeView', hash: '#directions' }">
          Все предложения
        </router-link>
        <router-link :to="{ name: 'HomeView', hash: '#faq' }">FAQ</router-link>
      </nav>

      <div class="docs">
        <router-link
          :to="{ name: doc.name }"
          v-for="(doc, index) in docs"
          :key="index"
        >
          {{ doc.title }}
        </router-link>
      </div>

      <a href="mailto:client@dajet.ru">client@dajet.ru</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      docs: [
        { name: "RequisitesView", title: "Реквизиты" },
        { name: "TermsView", title: "Условия использования" },
        { name: "PaymentSecureView", title: "Безопасность платежей" },
        { name: "PolicyView", title: "Политика конфиденциальности" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #374151;
  padding: 16px 0;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #fefefe;
      padding: 14.5px 40px;
      background: none;
      border: 1px solid #fefefe;
      border-radius: 6px;

      @media (max-width: 1279px) {
        font-size: 14px;
        line-height: 17px;

        padding: 14.5px 32px;
      }
    }
  }
}

.footer {
  background: #374151;
  border-radius: 12px 12px 0 0;
  padding: 24px 0;

  > .container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    > a {
      margin: 24px 0 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #d1d5db;
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      margin: 32px 0 0;

      @media (max-width: 767px) {
        justify-content: flex-start;
        flex-flow: column;
        align-items: flex-start;
        gap: 16px;
      }

      > a {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #fefefe;

        &:hover {
          color: #d8df6d;
        }

        @media (max-width: 1279px) {
          font-size: 16px;
          line-height: 120%;
        }
      }
    }

    > .docs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      margin: 24px 0 0;
      border-bottom: 1px solid #6b7280;
      padding: 0 0 24px;

      @media (max-width: 767px) {
        flex-flow: column;
        align-items: flex-start;
      }

      > a {
        color: #d1d5db;
        font-size: 14px;
      }
    }
  }
}
</style>
