import { createRouter, createWebHistory } from "vue-router";
import ClearLayout from "@/components/ClearLayout.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
    beforeEnter: (to, from, next) => {
      next({ name: "HomeView" });
    },
  },

  {
    path: "/",
    name: "HomeView",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },

  {
    path: "/requisites",
    name: "RequisitesView",
    component: () =>
      import(
        /* webpackChunkName: "RequisitesView" */ "../views/RequisitesView.vue"
      ),
  },

  {
    path: "/payment-secure",
    name: "PaymentSecureView",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSecureView" */ "../views/PaymentSecureView.vue"
      ),
  },

  {
    path: "/terms",
    name: "TermsView",
    component: () =>
      import(/* webpackChunkName: "TermsView" */ "../views/TermsView.vue"),
  },

  {
    path: "/policy",
    name: "PolicyView",
    component: () =>
      import(/* webpackChunkName: "TermsView" */ "../views/PolicyView.vue"),
  },

  {
    path: "/tour",
    component: ClearLayout,
    children: [
      {
        path: "",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          next({ name: "HomeView" });
        },
      },
      {
        path: ":id",
        name: "TourView",
        component: () =>
          import(/* webpackChunkName: "TourView" */ "../views/TourView.vue"),
      },
    ],
  },

  {
    path: "/direction",
    component: ClearLayout,
    children: [
      {
        path: "",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          next({ name: "HomeView" });
        },
      },
      {
        path: ":id",
        name: "DirectionView",
        component: () =>
          import(
            /* webpackChunkName: "DirectionView" */ "../views/DirectionView.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
